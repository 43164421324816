.event-host {
  background: #d6def1;
  border-radius: 10px;
}

.event-step3-main {
  background: #f5f7fe;
  border-radius: 10px;
  padding: 10px;

  .event-details-main .event-lbl {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .event-no {
    width: 217.98px;
    height: 15.25px;

    font-family: "Roboto Condensed";
    font-style: normal;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #3d3d3d;
  }

  .border-class {
    border: 1px solid lightgray;
    padding: 10px;
    margin-top: 10px;
    border-radius: 50px;
  }
  .link-generate {
    font-size: 18px;
    font-weight: 400;
  }
  .event-work-circle {
    .link-text {
      color: "#";
      font-size: 18px;
      font-weight: 400;
      color: #2c70b8;
      cursor: pointer;
    }
  }
  // .link-text:hover {
  //   color: #2c70b8;
  // }
}
