.add-floating-btn {
  position: fixed;
  bottom: 40px;
  right: 40px;
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  z-index: 999;
  align-items: center;
  cursor: pointer;
  border-radius: 50rem;
  background: linear-gradient(98.96deg, #00a3e5 21%, #d6225d 96.31%);

  i {
    color: white;
    font-size: 28px;
  }
}
