.event-step4-main {
  .event-image > img {
    border-radius: 10px !important;
    margin-top: 10px;
  }
  .attendees-list {
    // height: 100%;
    max-height: 500px;
    overflow: auto;
  }
  .event-image {
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      width: 100%;
      height: 200px;
      border-radius: 10px;
      background: rgba($color: #000000, $alpha: 0.3);
      z-index: 0;
    }
  }
  .event-inner-class {
    align-items: center;
    left: 25.5px;
    top: 34px;
    z-index: 9;
  }
  .host-main {
    margin-left: 10px;
    .host-style {
      font-weight: 100px;
      margin-bottom: 0 !important;
      color: white;
    }
    .host-name-style {
      margin-bottom: 0 !important;
      color: white;
    }
  }
  .event-name-main {
    position: absolute;
    left: 0px;
    top: 85px;
    .event-name {
      font-size: 24px;
      color: #ffff;
      margin-bottom: 0 !important;
    }
    .event-floor {
      width: 100%;
      min-width: 250px;
      margin-bottom: 0 !important;
      color: #ffff;
    }
  }
  .event-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 280.5px;
    top: 0px;
    background: #ffffff;
    border-radius: 6px;
    .event-month {
      // width: 24px;
      height: 10px;
      font-family: "Roboto Condensed";
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      text-transform: uppercase;
      color: #3e3157;
      flex: none;
      order: 0;
      flex-grow: 0;
    }
    .event-date {
      width: 24px;
      height: 5.23px;
      font-weight: 700;
      font-size: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      text-transform: uppercase;
      color: #3e3157;
    }
    .event-time-inner {
      // width: 45px;
      height: 0px;
      font-family: "Roboto Condensed";
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 7px;
      text-transform: uppercase;
      color: #3e3157;
      order: 2;
    }
  }
  .meeting-room-event {
    background: #d6def1;
    border-radius: 50px;
  }
  .meeting-time-event {
    background: #d6def1;
    border-radius: 50px;
  }
  .meeting-teams-event {
    background: #d6def1;
    border-radius: 50px;
  }
  .day-status-event {
    background: #a5f1da;
  }
}
