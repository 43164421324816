.event-step1-main {
  .square-image-display {
    border-radius: 10px;
    border: 2px dotted lightgrey;
  }

  .active-text {
    margin-left: 5px;
    color: #3e3157;
  }

  .default-text {
    margin-left: 5px;
    color: #7a7a7a;
  }

  .event-details {
    background: #f5f7fe;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 22px 12px;
    gap: 12px;
  }

  .MuiInputBase-input {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 30px !important;
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }

  textarea.event-text-aria {
    background: #f5f7fe;
  }

  .event-text-area {
    width: 100%;
    border-radius: 20px;
    height: 100px;
    padding: 10px;
  }
}

.radio_parent_choose_event {
  position: relative;
  flex: 1;
  margin-top: 15px;

  &>label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    margin: 0;
  }

  &>.cursor-pointer {
    &>svg {
      margin: auto;
    }

    &>span {
      display: block;
      text-align: center;
    }
  }
}