.disability {
    margin-top: 20px;
    padding: 10px;
}

.css-g1d714-ValueContainer {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.4rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    min-height: 50px;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.css-g1d714-ValueContainer {
    border-radius: 50rem;
}


.css-yk16xz-control {
    border-radius: 50rem;
}


.css-1pahdxg-control {
    border-radius: 50rem;
}

.css-2613qy-menu {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;

}

/* .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
} */

.css-1wa3eu0-placeholder {
    padding: .7rem;
}

/* .css-1okebmr-indicatorSeparator {
    display: none !important;
} */

.css-b8ldur-Input {
    margin-left: 10px !important;
}

/* .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
} */
.css-2b097c-container {
    z-index: 1051;
}

.css-g1d714-ValueContainer {
    border: none;
}

.css-1uccc91-singleValue {
    margin-left: 10px !important;
}