.menu_reg {


    position: absolute;
    width: 5%;
    height: 100%;
    left: 0px;
    top: 0px;

    background: linear-gradient(180deg, #5470AF 0%, #8563B9 100%);
    box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.35);
}

.radius-50
{
    border-radius: 50%;
}

.rect_header_reg {
    position: absolute;
    width: 100%;
    height: 10%;
    left: 0px;
    top: 0px;

    background: #918D8D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    text-align: center;
}

.img_logo_reg {
    z-index: 999;
    width: 170px;
    padding: 20px;
}

.content_paragraph_reg_step {
    position: relative;
    width: 60%;
    height: 127px;
    margin-left: 30%;
    margin-top: 2%;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

.content_paragraph_reg {
    position: relative;
    width: 60%;
    height: 127px;
    margin-left: 25%;
    margin-top: 2%;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #000000;
}

.upload_image_vehical {
    margin-left: 35%;
    margin-bottom: 100px;
    font-size: 25px;
}

.pad-12-reg {
    padding: 12px;
    z-index: 999;
}

.img_header_reg {
    z-index: 999;
    max-height: 50px !important;
    vertical-align: middle;
    border-style: none;
}


.pop_up_reg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 44px 88px 0px;
    gap: 103px;

    position: relative;
    width: 80%;
    height: auto;
    margin-left: 12%;
    margin-top: 25px;

    background: #FFFFFF;

    /* bbbb */
    box-shadow: 0px 0px 66px rgba(26, 72, 115, 0.12);
    border-radius: 4px;
}

.MuiInputBase-input.MuiOutlinedInput-input {
    border: none !important;
}

.MuiFormControl-root.MuiTextField-root {
    margin-top: 20px;
}

.width-full {
    width: 100%;
}

.auto-width {
    width: 45%;
}

.vehicle-no {
    font-size: 25px !important;
    width: auto !important;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.mb-form {
    margin-bottom: 60px;
}

.switch-class-reg {
    margin-top: 30px;
    display: flex;
    width: 45%;
    font-size: 0px;
}

.content_header_reg {
    text-align: center;
    margin-top: 30px;
    color: black;
    font-size: 35px;
}

.button-form-reg {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 25px;
    gap: 10px;


    height: 80px;

    background: #000000;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 220px;
    margin-left: 25%;
    width: 50%;
}

.text-sign-reg {
    width: 454.79px;
    height: 56px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 47.49px;
    line-height: 56px;

    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;

    color: #FFFFFF;
    margin-left: 37%;
}

.img_reg {
    vertical-align: middle;
    border-style: none;
    margin-left: 44%;
    width: 100px;
    margin-bottom: 20px;
}

.image_icon_upload {
    height: 100px;
    width: 100px;
}

.upload-file {
    width: 50% !important;
    margin-left: 25%;
    border: none !important;
}

.green_tick_reg {
    margin-top: 150px;
}

.remove-click-reg {
    pointer-events: none;
    background: gray;
}

.ml-3.spinner.spinner-white.spinner-reg {
    margin-left: 45% !important;
}

.terms-conditions {
    max-height: 300px;
    overflow: auto;
    background: #D9D9D9;
}

.button-top-none {
    margin-top: 60px !important;
}

.content-terms {
    padding: 30px;
}

.select2-container {
    width: 50% !important;
    margin-left: 25% !important;
}

.user-list {
    margin-left: 25%;
    margin-bottom: 50px;
    font-size: 25px;
}

.visitor-list {
    margin-left: 25%;
    margin-bottom: 50px;
    font-size: 25px;
    display: flex;
}

.host-image-div {
    display: flex;
    padding-left: 15px;
}

.visitor-list-host {
    margin-left: 25%;
    margin-bottom: 50px;
    font-size: 25px;
    display: flex;
}

.p-10px {
    padding: 10px;
}

.p-5px {
    padding: 5px;
}

.name-padding {

    padding-left: 10px;
}

.error-complete {
    margin-left: 25%;
    color: red;
}

@media only screen and (max-width: 600px) {

    .error-complete {
        margin-left: 0%;
        color: red;
    }

    .host-image-div {
        display: flex;
        padding-top: 25px;
        padding-left: 0px;
    }

    .visitor-list-host {
        margin-left: 0%;
        margin-bottom: 50px;
        font-size: 20px;
        display: inline-block !important;
    }

    .menu_reg {


        display: none;
    }

    .user-list {
        margin-top: 2%;
        margin-left: 10%;
        margin-bottom: 50px;
        font-size: 20px;
    }

    .select2-container {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .visitor-list {
        margin-left: 15%;
        margin-bottom: 50px;
        font-size: 20px;
        display: flex;
        margin-top: 2%;

    }

    .visitor-list-host {
        margin-left: 0%;
        margin-bottom: 50px;
        font-size: 20px;
        display: flex;
    }

    .p-10px {
        padding: 0px;
    }

    .m-l-10-p {
        margin-left: 10% !important;
    }

    .upload_image_vehical {
        margin-left: 5%;
        margin-bottom: 50px;
        margin-top: 10px;
        font-size: 20px;
    }

    .rect_header_reg {
        position: absolute;
        width: 100%;
        height: 60px;
        left: 0px;
        top: 0px;

        background: #918D8D;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
        text-align: center;
    }

    .content_paragraph_reg {
        position: relative;
        width: 100%;
        height: 127px;
        margin-left: 0%;
        margin-top: 10%;
        padding: 10px;

        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        text-align: center;

        color: #000000;
    }

    .pop_up_reg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 20px 0px;
        gap: 103px;

        position: relative;
        width: 90%;
        height: auto;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 25px;

        background: #FFFFFF;

        /* bbbb */
        box-shadow: 0px 0px 66px rgba(26, 72, 115, 0.12);
        border-radius: 4px;
    }

    .auto-width {
        width: 100%;
    }

    .switch-class-reg {
        margin-top: 30px;
        display: flex;
        width: 100%;
        font-size: 0px;
    }


    .text-sign-reg {
        width: 454.79px;
        height: 56px;

        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 500;
        font-size: 47.49px;
        line-height: 56px;

        /* identical to box height */
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.01em;

        color: #FFFFFF;
        margin-left: 32%;
    }

    .button-form-reg {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 25px;
        gap: 10px;


        height: 80px;

        background: #000000;
        border-radius: 4px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 150px;
        margin-left: 0;
        width: 100%;
        margin-top: 280px;
    }

    .button-top-none {
        margin-top: 60px !important;
    }

    .img_reg {
        vertical-align: middle;
        border-style: none;
        margin-left: 35%;
        width: 80px;
        margin-top: 20px;
    }

    .img_header_reg {
        display: none;
    }


    .img_logo_reg {
        z-index: 999;
        width: 240px;
        padding: 15px;
    }

    .content_header_reg {
        text-align: center;
        margin-top: 50px;
        color: black;
        font-size: 35px;
    }

    .green_tick_reg {
        padding: 20px;
    }

    .ml-3.spinner.spinner-white.spinner-reg {
        margin-left: 45% !important;
    }

    .remove-click-reg {
        pointer-events: none;
        background: gray !important;
    }
}