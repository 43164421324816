//
// Main Styles
//

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY BELOW INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////

// 1: Initialize
@import "init";

// 2: Bootstrap/Material framework includes
@import "~bootstrap/scss/bootstrap";
@import "vendors/react/material-ui/general";

// 3: Components
@import "components/utilities", "components/burger-icon", "components/card",
"components/wizard", "components/type", "components/breadcrumb",
"components/nav", "components/alert", "components/buttons", "components/code",
"components/dropdown", "components/forms", "components/grid",
"components/modal", "components/progress", "components/table",
"components/popover", "components/tooltip", "components/accordion",
"components/datatable", "components/checkbox", "components/radio",
"components/switch", "components/option", "components/dialog",
"components/strikethrough", "components/separator", "components/image-input",
"components/label", "components/loader", "components/symbol",
"components/pulse", "components/page-loader", "components/quick-search",
"components/scrolltop", "components/spinner", "components/sticky-toolbar",
"components/svg-icon", "components/svg", "components/timeline",
"components/timeline-2", "components/timeline-3", "components/timeline-4",
"components/timeline-5", "components/timeline-6", "components/overlay",
"components/wave", "components/list", "components/bullet", "components/navi",
"components/ribbon", "components/offcanvas", "components/toggle",
"components/add-booking-btn";

// 4: Plugins
@import "vendors/plugins/dropzone", "vendors/plugins/perfect-scrollbar",
"vendors/plugins/prismjs", "vendors/plugins/react-bootstrap-table2";

// 5: Layout
@import "layout/init";

// 6 wizard
@import "pages/wizard/wizard-4.scss";

////////////////////////////////////////////////////////////////
///////////// DO NOT MODIFY ABOVE INCLUDE ORDER ////////////////
////////////////////////////////////////////////////////////////
.floor-plan-btn-group {
  background: #3e3157;
  border-radius: 50rem;
  max-width: 200px;
  display: flex;
}

.overflow-inherit {
  overflow: inherit !important;
}

.loader-listed span {
  width: 100%;
}

.MuiTab-labelIcon {
  min-height: 40px;
}

.width-55rem {
  width: 5.5rem;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.ob-fit-contain {
  object-fit: contain;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(98.96deg, #00a3e5 21%, #d6225d 96.31%);
  outline: 1px solid slategrey;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dashboard-divs .infinite-scroll-component {
  padding: 5px 15px;
}

.canvas-main-block canvas {
  // height: 100%;
  // width: 100%;
  background-position: 100% 100%;
  z-index: 99;
}

.canvas-main-block {
  max-height: 700px;
  width: 100%;
  height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-floor-plan-allocate-icon {
  border-radius: 10px;
  background: rgb(163 42 42 / 12%);
  padding: 10px;
}

.site-floor-plan-allocate-block {
  justify-self: inherit;
  display: flex;
  width: max-content;
  flex-wrap: wrap;
  max-width: 32%;
  text-align: revert;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.card.card-custom.floor-plan>.card-header .card-toolbar {
  justify-content: end;
}

.font-14px {
  font-size: 14px;
}

.font-13px {
  font-size: 13px;
}

.floor-plan .acount-header-content-count-item {
  max-height: 100px;
  max-width: 100px;
  height: 100px;
  width: 100px;
}

.MuiButtonBase-root.MuiChip-root.active.MuiChip-clickable {
  background: #e54ea1;
  color: white;
}

// .mitting-bg .booking-card-type-icon-block {
//   margin-top: -10px;
// }
.booking-card-type-icon-block {
  display: flex;
  justify-content: end;
  // position: absolute;
  right: 5px;
  bottom: 5px;
}

.floor-plan-btn-group button.btn {
  padding: 0;
  border-radius: 0;
  margin: 0;
}

.floor-plan .card-header {
  padding-bottom: 1.25rem !important;
  padding-top: 1.25rem !important;
  align-items: center;
}

.floor-plan .card-title {
  padding-top: 20px;
}

.floor-plan.card .card-header .btn.btn-back-button {
  position: absolute;
  left: 20px;
  top: 0;
  color: #3e3157;
  border: none;
  align-items: center;
  display: flex;
  font-weight: 700;
}

// Jaydeep styles on 7th Oct 2022 start...
.general-settings-container {
  border: 1px solid #dbdad9;
  padding: 1.5rem;
  border-radius: 12px;
}

.general-settings-title {
  font-size: 22px;
  font-weight: 700;
  color: #2c70b8;
  line-height: 26px;
}

.general-settings-image {
  width: 88px;
  height: 88px;
  border-radius: 8px;
}

.general-settings-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 2rem;
  gap: 1.5rem;
}

.general-settings-listitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.general-settings-left-side-subcontainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.general-settings-title-typography {
  font-size: 22px;
  font-weight: 700;
  color: #2c70b8;
  line-height: 26px;
  margin-top: 18px;
}

.general-settings-description-typography {
  font-size: 14px;
  font-weight: 500;
  color: #3e3157;
}

.settings-modal-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  color: #3d3d3d;
}

.modal-api-key-container {
  border-radius: 12px;
  background: #edf2fe;
  padding: 12px 6px;
  width: 100%;
}

.modal-api-key-input {
  height: 49px;
  width: 100%;
  border-radius: 47px;
  border: 1px solid #dbdad9;
}

.modal-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-general-settings-footer {
  text-align: center;
  margin-bottom: 20px;
}

.modal-general-settings-submit-button {
  width: 80%;
}

// Jaydeep styles on 7th Oct 2022 end...

.update-availability-popup .modal-dialog {
  min-width: 600px;
}

tr.bg-blue-row {
  background: #edf2fe;
}

.floor-table-title {
  font-size: 14px;
  color: #7a7a7a;
  font-weight: 300;
}

.floor-table-value {
  font-size: 24px;
  font-weight: 500;
  color: #3d3d3d;
}

.dashboard-user-image span.plus-color {
  cursor: pointer;
}

.square-image-display span {
  color: #2c70b8;
  font-weight: 300;
  font-size: 16px;
  display: block;
}

.acount-header-image-block {
  max-width: 125px;
  max-height: 125px;
  border-radius: 50rem;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
}

.square-image-display {
  width: 100%;
  min-height: 120px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border: 2px dotted lightgrey;
  text-align: center;
}

button.btn.btn-primary.btn-save {
  min-width: 142px;
  border-radius: 50rem;
  font-size: 16px;
  font-weight: 700;
}

button.btn.btn-white-cancle {
  background: #fff;
  min-width: 142px;
  border-radius: 50rem;
  color: #7a7a7a;
  font-size: 16px;
  font-weight: 700;
}

.MuiFormControl-fullWidth.MuiFormControl-root.MuiTextField-root.border-none.form-control {
  padding: 0 !important;
  margin: 0 !important;
}

.account-form .was-validated .form-control:invalid,
.form-control.is-invalid {
  background: transparent !important;
  background-image: none !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #f64e60 !important;
  padding-right: calc(1.5em + 1.3rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F64E60' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F64E60' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.325rem) center;
  // background-size: calc(0.75em + 0.65rem rem) calc(0.75em + 0.65rem);
}

.form-control.border-none .MuiOutlinedInput-root {
  border-radius: 50rem;
}

.form-control.border-none .MuiOutlinedInput-input {
  border: none !important;
}

.dashboard-user-image span {
  margin-right: -8px;
  border: 3px solid #fff;
  border-radius: 100%;
  background: #686dc0;
  color: #fff;
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  justify-content: center;
  text-transform: uppercase;
  font-size: 15px;
}

.booking-card-type-icon {
  background: #fff;
  padding: 4px;
  border-radius: 50%;
  // max-width: max-content;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-icon-svg {
  display: flex;
  padding: 10px;
}

.dashboard-user-image {
  display: flex;
  justify-self: end;
  margin-left: 15px;
}

span.max-user-count {
  height: 30px;
  width: 30px;
  background: #e54ea1;
  border-radius: 50rem;
  font-size: 13px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: auto 5px;
}

.bg-blue-card.card .card-header {
  background: #f5f7fe !important;
}

.account-profile-tab-block .MuiTab-wrapper {
  flex-direction: inherit;
  font-size: 16px;
  font-weight: 400;
  color: #7a7a7a;
}

.account-profile-tab-block .MuiTabs-root .MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
  color: #d60b52;

  svg {
    stroke: #d60b52 !important;

    path {
      stroke: #d60b52 !important;
    }
  }
}

.account-profile-tab-block .PrivateTabIndicator-colorSecondary-27 {
  background-color: #d60b52;
}

// .account-profile-tab-block .MuiTab-wrapper svg {
//   fill: #7a7a7a;
// }
// .account-profile-tab-block
//   .MuiTabs-root
//   .MuiButtonBase-root.Mui-selected
//   .MuiTab-wrapper
//   svg {
//   fill: #d60b52;
// }
.acount-header-block {
  width: 100%;
  background: #3e3157;
  min-height: 390px;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.acount-header-content-block {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 30px;
}

.acount-header-content-count-block {
  display: flex;
  column-gap: 30px;
}

.acount-header-block .btn.btn-back-button {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  border: none;
  align-items: center;
  display: flex;
}

.acount-header-content-count-item h3 {
  color: #fff;
  font-size: 44px;
}

.acount-header-content-count-item.no-border {
  border: none;
  border-radius: 0;
  overflow: visible;
}

.font-23px {
  font-size: 23px;
}

.acount-header-content-count-item {
  display: flex;
  max-height: 116px;
  overflow: hidden;
  max-width: 116px;
  width: 116px;
  height: 116px;
  border-radius: 50rem;
  border: 10px solid;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #7a7a7a;
  flex-direction: column;
  text-align: center;
  padding: 5px 8px;

  .mb-2 {
    display: flex;
    align-items: center;
  }
}

.acount-header-content-count-item.border-sites span.cutome-color {
  color: #2c70b8;
}

.acount-header-content-count-item.border-sites {
  background: #fff;
  border-color: #2c70b8;
}

.acount-header-content-count-item.border-daypass span.cutome-color {
  color: #2c9fb8;
}

.acount-header-content-count-item.border-daypass {
  background: #fff;
  border-color: #2c9fb8;
}

.acount-header-content-count-item.border-meetingRoom span.cutome-color {
  color: #e54ea1;
}

.acount-header-content-count-item.border-meetingRoom {
  background: #fff;
  border-color: #e54ea1;
}

.acount-header-content-count-item.border-space span.cutome-color {
  color: #16b587;
  line-height: 20px;
}

.acount-header-content-count-item.border-space {
  background: #fff;
  border-color: #16b587;
  text-align: center;
}

.acount-header-content-count-item.border-ev-parking span.cutome-color {
  color: #277eda;
}

.acount-header-content-count-item.border-ev-parking {
  background: #fff;
  border-color: #277eda;
}

.acount-header-content-count-item.border-bike-parking span.cutome-color {
  color: #3eb7b1;
}

.acount-header-content-count-item.border-bike-parking {
  background: #fff;
  border-color: #3eb7b1;
}

.acount-header-content-count-item.border-parking span.cutome-color {
  color: #8f4490;
}

.acount-header-content-count-item.border-parking {
  background: #fff;
  border-color: #8f4490;
}

.acount-header-content-count-item.border-users span.cutome-color {
  color: #2c70b8;
}

.acount-header-content-count-item.border-users {
  background: #fff;
  border-color: #2c70b8;
}

.table.table-head-custom-bold tbody tr {
  box-shadow: 0 0.25rem 0.25rem 0 rgb(0 0 0 / 5%),
    0 -0.25rem 0.25rem 0 rgb(0 0 0 / 5%);
  height: 92px;
  border-radius: 8px !important;
}

.table.table-head-custom-bold {
  border-collapse: separate;
  border-spacing: 0 20px;
}

.table.table-head-custom-bold thead tr,
.table.table-head-custom-bold thead th {
  text-transform: uppercase;
  letter-spacing: auto;
  color: #3d3d3d;
  font-weight: 700;
  font-size: 13px;
}

.card.card-custom.bg-blue-card.card .card-header h3.card-label {
  font-size: 30px;
  font-weight: 300;
  color: #3d3d3d;
}

.availability-block {}

button.btn.btn-add-new {
  border: 1px solid #3e3157;
  border-radius: 52.1654px;
  min-width: 125px;
  width: 100%;
  font-weight: 700;
  background-color: #fff;
}

.availability-block .left-side-block {
  background-color: #edf2fe;
  padding: 15px;
}

.form-control .search-wrapper span {
  background: transparent;
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  margin: auto;
  font-size: 15px;
  padding: 2px;
}

.booked-input-disabled .date-picker-clock {
  fill: #fff;
}

.booked-input-disabled .form-control.datetimepicker-input {
  text-indent: 25px;
}

svg.date-picker-clock {
  position: absolute;
  left: 13px;
  top: 13px;
  z-index: 999 !important;
  width: 25px;
  // fill: #7a7a7a;
}

.blocked-bg {
  background-color: #393f5e;
}

.update-availability-popup .color-box-list li.list-inline-item span.room-block-15 {
  background-color: #afecff;
}

.update-availability-popup .color-box-list li.list-inline-item span.room-block-15.booked {
  background: #d60b52;
}

.update-availability-popup .available-bg {
  background-color: #afecff;
}

.update-availability-popup .booked-bg {
  background: #d60b52;
}

.update-availability-popup .booked-allDay .color-box-list li.list-inline-item span.room-block-15 {
  background-color: #393f5e;
}

.booked-input-disabled .form-control.datetimepicker-input {
  background-color: #393f5e;
  color: #fff;
}

.btn.btn-block.btn-confirm.rounded-pill {
  border: 1px solid #2c70b8;
  color: #2c70b8;
  margin-top: 12px;
}

.form-control .search-wrapper {
  border: none;
  margin: 0;
}

.btn.btn-availability-update {
  color: #e54ea1;
  border: 1px solid #e54ea1;
}

.update-availability-confirm .confirm-content {
  font-size: 15px;
  color: #3d3d3d;
  line-height: 24px;
  font-weight: 500;
  height: 100px;
  margin: auto;
  text-align: center;
  align-content: center;
  display: grid;
}

.popup-calendar-view {
  background-color: #f5f7fe;
  width: 100%;
  display: flex;
  padding: 10px;
}

.availability-block .right-side-block {}

.details-proceess-block {
  display: flex;
}

.update-availability-confirm .modal-content,
.update-availability-confirm .modal-dialog {
  max-width: 310px;
  max-height: 400px;
  border-radius: 13px;
}

.availability-item .card.card-custom {
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 16%);
  border-radius: 1rem;
}

.availability-block-proccess-block .Donut-multiple {
  margin: auto !important;
}

.availability-item .edit-icon {
  position: absolute;
  right: 0;
  min-width: 40px;
  min-height: 40px;
  background: #fff;
  border-radius: 50rem;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 12%);
}

.details-proceess-block .progress {
  margin-right: 12px;
}

.availability-item .details-block {
  display: flex;
  padding: 0 10px;
  max-height: 120px;
  margin-left: 20px;
}

.availability-item .details-head {
  width: min-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 12px;
}

.availability-item .details-head .title {
  font-size: 20px;
  display: block;
  width: 100%;
  font-weight: 400;
}

.availability-item .details-head .capacity-block svg {
  width: 14px !important;
}

.availability-item .details-head .capacity-block .title {
  font-size: 12px;
  color: #3d3d3d;
  margin: auto auto 5px auto;
}

span.capacity-number {
  width: 30px;
  height: 30px;
  border: 1px solid #e54ea1;
  border-radius: 50rem;
  font-size: 15px;
  padding: 5px;
  color: #e54ea1;
  margin: auto 5px;
}

.availability-item .details-head .capacity-block {
  font-size: 12px;
  display: block;
  width: 100%;
}

.progress {
  width: 100px;
  height: 100px !important;
  // float: left;
  line-height: 100px;
  background: none;
  margin: auto;
  box-shadow: none;
  position: relative;
}

.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #cdd5e8;
  position: absolute;
  top: 0;
  left: 0;
}

.progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.progress .progress-left {
  left: 0;
}

.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}

.progress .progress-right {
  right: 0;
}

.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  // background: #44484b;
  // font-size: 24px;
  // color: #fff;
  // line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}

.progress.blue .progress-bar {
  border-color: #049dff;
}

.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.day-pass .progress-bar {
  border-color: #2c9fb8;
}

.progress.parking .progress-bar {
  border-color: #8f4490;
}

.progress.mitting-charging .progress-bar {
  border-color: #277eda;
}

.mitting-charging-bg {
  background-color: #277eda;
}

.progress.visitor-bike .progress-bar {
  border-color: #3eb7b1;
}

.visitor-bike-bg {
  background-color: #3eb7b1;
}

.progress.day-pass .progress-right .progress-bar {
  animation: loading-3 1.8s linear forwards;
}

.progress.day-pass .progress-left .progress-bar {
  animation: none;
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}

.availability-block-title span {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #3d3d3d;
}

.availability-block-title h3 {
  color: #3d3d3d;
  font-size: 36px;
  font-weight: 400;
}

.availability-block-proccess-block .col-md-3 {
  border: 1px solid #dbdad9;
  border-radius: 8px;
  padding: 10px 15px;
  max-width: 280px;
}

.availability-round-process-content {
  display: flex;
  width: 100%;
  padding: 5px;
  align-items: center;
  margin: auto;
  justify-content: space-evenly;
}

.process-icon svg {
  width: 22px;
}

.process-text span {
  background-color: #d6def1;
  margin-bottom: 5px;
}

.process-text {
  display: flex;
  max-width: max-content;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0 10px;
  color: #3d3d3d;
}

.availability-block-proccess-block .edit-icon {
  position: absolute;
  right: 0;
  min-width: 40px;
  min-height: 40px;
  background: #fff;
  border-radius: 50rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 9;
  display: flex;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
}

.process-icon {
  min-width: 55px;
  height: 55px;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  border-radius: 50rem;
}

.availability-round-process {
  width: 100px;
  height: 100px;
  border: 12px solid #cdd5e8;
  border-radius: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.availability-round-process-value span {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: #3e3157;
  display: block;
  margin-bottom: -3px;
}

.availability-round-process-value {
  font-size: 13px;
  font-weight: 300;
  color: #3e3157;
  text-align: center;
}

.avg-util span {
  line-height: 13px !important;
  margin-bottom: 5px;
}

.admin-booking-page-block.card .card-body {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-input-block {
  display: flex;
}

.space-input-label {
  display: flex;
  min-width: max-content;
  font-size: 15px;
  font-weight: 400;
}

.space-input-block input.form-controle.count-input {
  width: 50px;
  text-align: center;
  border-radius: 100%;
  margin: 0 5px;
  border: 1px solid lightgrey;
}

.space-input-block button {
  background: #2c9fb8;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #fff;
  font-size: 30px;
}

.admin-booking-page-box {
  max-width: max-content;
  padding: 15px;
  margin: auto;
}

input.form-fonrole {
  max-width: 50px;
  text-align: center;
  padding: 10px;
  margin: 0;
  border: 1px solid #dbdad9;
  border-radius: 25px;
  justify-content: center;
  text-indent: 0px;
}

.update-space-popup .modal-content {
  max-width: 375px;
}

.admin-booking-page-content-item .edit-icon svg {
  width: 12px;
}

.admin-booking-page-content-item.day-pass-color {
  outline-color: #2c9fb8;
  color: #2c9fb8;
}

.admin-booking-page-content-item.visitor-color {
  outline-color: #686dc0;
  color: #686dc0;
}

.admin-booking-page-content-item.meeting-color {
  outline-color: #e54ea1;
  color: #e54ea1;
}

.admin-booking-page-content-item.parking-color {
  outline-color: #8f4490;
  color: #8f4490;
}

.admin-booking-page-content-item.charging-color {
  outline-color: #277eda;
  color: #277eda;
}

.admin-booking-page-content-item.bike-color {
  outline-color: #3eb7b1;
  color: #3eb7b1;
}

.admin-booking-page-content-item {
  position: relative;
  font-size: 22px;
  background: #fff;
  margin-right: 33px;
  width: 80px;
  height: 80px;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 100%;
  outline: 10px solid;
}

.admin-booking-page-content-item .edit-icon {
  position: absolute;
  right: -12px;
  bottom: 0;
  background: #fff;
  min-width: 30px;
  justify-content: center;
  display: flex;
  min-height: 30px;
  border-radius: 100%;
  align-items: center;
  cursor: pointer;
}

.admin-booking-page-title span {
  font-size: 15px;
}

.admin-booking-page-title h3 {
  font-size: 38px;
  color: #fff;
  line-height: 44px;
  margin-bottom: 22px;
}

.admin-booking-page-title {
  color: #fff;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 22px;
}

.admin-booking-page-block.card {
  background-image: url("/media/bg/admin-booking-bg.png");
  height: 100%;
  max-height: 800px;
  background-size: cover;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form.login-signin .signin_form .form-group .form-control.form-control-solid {
  font-size: 18px;
  font-weight: 300;
  background-color: #fff;
}

button.fc-myCustomButton-button.fc-button.fc-button-primary,
button.fc-nextcustomButton-button.fc-button.fc-button-primary,
.calendar-view-booking .fc button.fc-button.fc-button-primary,
button.fc-button.fc-button-primary:focus,
.calendar-view-booking .fc button.fc-button.fc-button-primary:active,
.calendar-view-booking .fc .fc-button-primary:not(:disabled):active:focus {
  background: transparent;
  border: none;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  box-shadow: none;
}

.fc .fc-toolbar {
  background: #d6def1;
}

.calendar-view-booking .fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0;
}

.login-form.login-signin .signin_form .form-group {
  margin-bottom: 12px;
}

.booking-item.no-booking-card {
  background: #eef0f5;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
}

.login-form.login-signin .signin_form .btn.btn-primary.disabled,
.login-form.login-signin .signin_form .btn.btn-primary:disabled {
  background-color: #dbdad9;
  border-color: #dbdad9;
}

.signin_form .rounded-pill .select2-container--default .select2-selection--single {
  background: transparent;
  min-height: 50px;
  height: auto;
  padding: 1.25rem 1.5rem;
}

.round-image-display {
  width: 120px;
  height: 120px;
  box-shadow: none;
  border-radius: 50rem !important;
  display: flex;
  justify-content: center;
  // align-items: center;
  position: relative;
  margin: 0 0 15px 15px;
  overflow: hidden;
  background-color: #2c70b8;
}

.calendar-book-active {
  content: " ";
  padding: 0px 7px;
  margin: auto 1px;
  border-radius: 100%;
}

.fc .fc-day-other .fc-daygrid-day-top {
  margin: auto;
  color: #939393;
  font-size: 21px;
  opacity: 1;
}

.fc .fc-daygrid-day-top a {
  font-size: 21px;
  color: #000;
}

.fc .fc-daygrid-day-top {
  margin: auto;
}

.fc .fc-col-header-cell-cushion {
  color: #3e3157;
  font-size: 14px;
}

.fc .fc-day-today .fc-daygrid-day-frame,
.calendar-view-booking .fc .fc-highlight {
  background: #abf0ff;
}

.calendar-view-booking .fc .fc-event .fc-event-main {
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.calendar-view-booking .fc .fc-daygrid-event-harness {
  width: 100%;
}

.calendar-view-booking .fc .fc-direction-ltr .fc-daygrid-event.fc-event-end {
  width: 100%;
}

.calendar-user-image span.plus-color {
  background: #edf2fe;
  color: #2c70b8;
}

.calendar-user-image img,
.calendar-user-image span {
  margin-right: -8px;
  border: 3px solid #fff;
  border-radius: 100%;
  background: #686dc0;
  color: #fff;
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
}

.calendar-book-span {
  // display: flex;
  max-width: 50%;
}

.calendar-user-image {
  display: flex;
  max-width: 50%;
}

.fc .fc-daygrid-day-frame {
  background: #ffffff;
}

.fc-theme-standard td,
.fc-theme-standard th {
  background: #edf2fe;
}

.calendar-view-header-title {
  width: 100%;
  justify-content: center;
  display: flex;
  font-size: 17px;
  color: #000000;
  font-weight: 400;
}

.calendar-view-header {
  width: 100%;
  display: flex;
  background: #d6def1;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  padding: 10px;
}

.fc .fc-daygrid-day-frame {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.calendar-view-booking .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: absolute;
  min-height: 2em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
}

.fc .fc-h-event {
  background: none;
  border: none;
  display: flex;
}

.fade.mitting-room-popup.modal.show .modal-dialog .modal-body .popup-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #3d3d3d;
}

.mitting-room-popup .meeting-room-filter .filter-block {
  width: 100%;
}

.mitting-room-popup .meeting-room-filter {
  display: flex;
  justify-content: start;
  min-height: 70px;
  align-items: center;
  margin: auto;
}

.mitting-room-popup .close-btn-section {
  position: absolute;
  z-index: 9999;
  cursor: pointer;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 70px;
}

.room-book-item {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 460px;
  max-height: 500px;
}

.link-to-map {
  color: #2c70b8;
  font-size: 14px;
  font-weight: 400;
}

.capacity-content .capacity-block {
  display: inline-flex;
  border: 1px solid #e54ea1;
  width: 30px;
  border-radius: 90px;
  color: #e54ea1;
  font-weight: 400;
  height: 30px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.room-book-item-content-title {
  font-size: 20px;
  font-weight: 400;
  color: #3d3d3d;
}

.room-book-item-content-sub-title {
  font-size: 13px;
  font-weight: 300;
  color: #3d3d3d;
  width: 100%;
  display: block;
  margin-top: -4px;
}

.capacity-content {
  width: 100%;
  display: block;
}

.capacity-title {
  width: 100%;
  display: block;
  font-weight: 400;
  font-size: 10px;
  display: block;
  text-transform: uppercase;
  color: #3d3d3d;
}

.col-md-6.right-selction {
  display: flex;
  justify-content: end;
  align-items: end;
  justify-self: end;
}

.mitting-room-popup .right-selction .btn-outline-primary,
.mitting-room-popup .right-selction .btn-outline-primary:hover,
.mitting-room-popup .right-selction .btn-outline-primary:focus {
  border: 1px solid #e54ea1 !important;
  color: #e54ea1 !important;
  font-size: 14px;
  background: transparent !important;
}

.room-book-item-block {
  max-width: 330px;
  padding: 15px;
  margin: auto 15px 15px 0;
  flex: 0 0 23%;
  border-radius: 12px;
}

.fade.mitting-room-popup.modal.show .modal-dialog .modal-header {
  background: #f5f7fe;
}

.fade.mitting-room-popup.modal.show .modal-dialog .modal-content {
  max-height: inherit;
  height: inherit;
  box-shadow: none;
  border-radius: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.room-book-item-image-block {
  min-height: 225px;
  max-height: 225px;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.fade.mitting-room-popup.modal.show .modal-dialog {
  max-width: 100%;
  width: calc(100% - 75px);
  justify-content: end;
  align-items: center;
  height: 100%;
  margin: auto auto;
  align-self: center;
  justify-self: end;
  right: -38px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.fade.mitting-room-popup.modal.show {
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
}

.calendar-booking-craete-popup .modal-title {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: inherit;
  align-items: center;
}

.aside-menu {
  background-color: transparent;
}

#kt_body.aside-minimize .aside-menu .menu-nav {
  padding: 0;
}

.btn-cancel {
  border: 1px solid #a32a2a;
  color: #a32a2a;
}

.bg-gray-cutome {
  background: #dee1eb;
}

.calendar-booking-craete-popup .modal-dialog-centered {
  max-width: 700px;
}

.pro-image-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 100%;
  margin: auto 10px;
}

.font-15px {
  font-size: 15px !important;
}

.font-18px {
  font-size: 18px !important;
}

.color-gray {
  color: #3d3d3d !important;
}

#kt_body .subheader {
  align-items: center;
  min-height: 80px;
  max-height: 80px;
  background: #fff;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 6%);
  margin-bottom: 10px;
}

.open-sidebar-icon {
  cursor: pointer;
}

#kt_body.aside-minimize .aside-brand {
  background: transparent;
  min-height: 80px;
  max-height: 80px;
}

.aside-minimize .aside-brand .full-logo,
.aside-minimize .aside-brand .close-menu-icon {
  display: none;
}

#kt_body:not(.aside-minimize) .aside-brand .icon-logo,
#kt_body:not(.aside-minimize) .open-sidebar-icon {
  display: none;
}

#kt_body:not(.aside-minimize) .aside-brand .brand-logo {
  padding: 0 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#kt_body.aside-minimize .aside {
  width: 70px !important;
}

#kt_body.aside-minimize .aside .aside-primary {
  width: inherit;
}

#kt_body.aside-minimize .aside-menu .menu-nav>.menu-item>.menu-link {
  padding: 9px 10px;
}

@media (min-width: 992px) {
  .aside-fixed.aside-minimize .wrapper {
    padding-left: 70px;
  }
}

.aside {
  background: linear-gradient(180deg, #5470af 0%, #b963ad 100%);
  box-shadow: 4px 0px 8px rgba(0, 0, 0, 0.35);
}

.aside-brand {
  background: #f5f7fe;
  width: 100%;
  min-height: 80px;
  vertical-align: middle;
  justify-content: center;
}

.aside-menu .menu-nav>.menu-item>.menu-link .menu-text {
  color: #ffffff;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link,
.aside-menu .menu-nav>.menu-item>.menu-link,
.menu-link,
.aside-menu .menu-nav>.menu-item>.menu-link:hover,
.menu-link,
.aside-menu .menu-nav>.menu-item>.menu-link:focus {
  background-color: transparent !important;
}

.aside-menu .menu-nav>.menu-item.menu-item-active>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-active:hover>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-active:focus>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item:hover>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item:focus>.menu-link .menu-text {
  color: #ffffff !important;
}

.aside-menu .menu-nav>.menu-item.menu-item-active,
.aside-menu .menu-nav>.menu-item.menu-item-active:hover,
.aside-menu .menu-nav>.menu-item.menu-item-active:focus,
.aside-menu .menu-nav>.menu-item:hover,
.aside-menu .menu-nav>.menu-item:focus {
  background: rgba(42, 65, 116, 0.44);
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav>.menu-item>.menu-link .menu-bullet,
.aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav>.menu-item>.menu-link .menu-text {
  display: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.login.login-4 .login-container {
  background: #edf2fe;
}

.login.login-4 .login-container .login-content {
  max-width: 600px;
  width: 100%;
  // background: #F5F7FE;
  background: transparent;
}

.login.login-4 .login-container .login-content .wizard-nav {
  padding: 0;
}

table tr td span.label.label-lg {
  min-height: 40px;
}

.min-h-100vh {
  min-height: 100vh !important;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step {
  padding: 0.75rem 0;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  margin-bottom: 1.5rem;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  width: 46px;
  height: 46px;
  border-radius: 12px;
  background-color: #f3f6f9;
  margin-right: 1rem;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-check {
  display: none;
  font-size: 1.4rem;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-icon .wizard-number {
  font-weight: 600;
  color: #3f4254;
  font-size: 1.35rem;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
  color: #181c32;
  font-weight: 600;
  font-size: 1.24rem;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-desc {
  color: #b5b5c3;
  font-size: 0.925rem;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-icon {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  background-color: #e1f0ff;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-icon .wizard-check {
  color: #3699ff;
  display: inline-block;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-icon .wizard-number {
  display: none;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-title {
  color: #b5b5c3;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="done"] .wizard-label .wizard-desc {
  color: #d1d3e0;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon {
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  background-color: #e1f0ff;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .wizard-check {
  color: #3699ff;
  display: none;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .wizard-number {
  color: #3699ff;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-title {
  color: #181c32;
}

.login.login-4 .login-container .login-content .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-label .wizard-desc {
  color: #b5b5c3;
}

.login.login-4 .login-container .login-content.login-content-signup {
  width: 700px;
}

.btn.btn-office-login:hover,
.btn.btn-office-login:focus {
  color: #fff;
}

.btn-office-login {
  background: #eb3c00;
  box-shadow: 0px 0px 7.11347px rgba(0, 0, 0, 0.18);
  border-radius: 52.1654px;
  color: #fff;
}

.btn-office-login span {
  margin-right: 60px;
}

.signup-title {
  font-size: 30px;
  font-weight: 400;
  color: #3d3d3d;
}

img.login-img-2 {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}

.text-gray {
  color: #7a7a7a;
}

a.btn-link {
  color: #2c70b8;
}

.fv-help-block {
  color: #ff0000;
  text-align: left;
  margin-left: 1.5rem;
}

.login.login-4 .login-aside {
  // background: linear-gradient(180deg, #5470af 0%, #a765ad 100%);
  width: 40%;
  max-width: 40%;
  background-size: cover;
  max-height: inherit;
}

.login.login-4 .login-aside .login-conteiner {
  height: 100%;
}

.login-logo img {
  max-height: 74px;
}

.login-form form {
  width: 100%;
  padding: 10px;
  display: inherit;
}

.login-form {
  background: #f5f7fe;
  border-radius: 12px;
  padding: 24px;
  text-align: center;
}

.btn.btn-primary {
  background: linear-gradient(98.96deg, #00a3e5 21%, #d6225d 96.31%);
  border-radius: 52.1654px;
}

span.button-icon svg {
  width: 23px;
}

span.button-icon {
  right: 20px;
  bottom: 5px;
}

svg.office-365-icon {
  width: 18px;
  margin: auto 10px;
  height: auto;
}

@media (min-width: 992px) {
  .login.login-4 .login-aside .login-conteiner {
    min-height: 610px !important;
    background-size: 210px;
  }

  .svg-green {
    right: -220px;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .login.login-4 .login-aside .login-conteiner {
    min-height: 420px !important;
    background-size: 200px;
  }
}

@media (min-width: 1199px) and (max-width: 1516px) {
  // .filter-icons {
  //   margin-left: 60px;
  // }

  .svg-green {
    right: -110px;
  }
}

@media (min-width: 1920px) {
  .svg-green {
    left: 40px;
  }
}

@media (max-width: 991.98px) {
  .login.login-4 .login-container .wizard-nav {
    padding: 0;
    -ms-flex-line-pack: center;
    align-content: center;
  }

  .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step {
    margin-bottom: 0.5rem;
  }

  .login.login-4 .login-aside {
    width: 100% !important;
  }

  .login.login-4 .login-aside .login-conteiner {
    min-height: 210px !important;
    background-size: 200px;
  }
}

@media (max-width: 575.98px) {
  .login.login-4 .login-container {
    width: 100%;
  }

  .login.login-4 .login-container .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step {
    width: 100%;
  }

  .login.login-4 .login-container .wizard-nav .wizard-steps .wizard-step .wizard-wrapper .svg-icon {
    display: none;
  }

  .login.login-4 .login-container .login-content {
    width: 100%;
  }

  .login.login-4 .login-container .login-content.login-content-signup {
    width: 100%;
  }

  .login.login-4 .login-aside {
    width: 100% !important;
  }
}

@media (max-width: 1800px) {
  .login.login-4 .login-aside {
    width: 50%;
  }
}

.notification-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification,
.notification-container {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.notification {
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 700;
  margin: 0 0 5px;
}

.notification:focus,
.notification:hover {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.notification-enter.notification-enter-active,
.notification-leave {
  visibility: visible;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: Notification;
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "\2713";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "\26A0";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "\26A0";
}

.invalid-datepicker-feedback {
  color: red;
}

.form-control.form-control-solid {
  background: transparent;
  border: 1px solid #e4e6ef;
}

.feedback {
  margin-top: 1.25rem;
}

.form-group label {
  text-transform: capitalize;
}

.hide {
  display: none;
}

.nav-link {
  cursor: pointer;
}

.contest-title {
  display: flex;
  align-self: center;
  max-width: max-content;
  width: auto;
  margin: auto;
  position: absolute;
  z-index: 999;
  top: -20px;
  left: 0;
  right: 0;
  padding: 10px;
  background: #1bc5bd;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 5px;
}

.font-20px {
  font-size: 20px;
}

.overlay .overlay-layer-dark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}

.card.card-custom.overlay:hover .overlay-wrapper,
.card.card-custom.overlay.active .overlay-wrapper {
  background: #fff;
  opacity: 0.5;
}

.card.card-custom.overlay {
  cursor: pointer;
}

.card.card-custom.overlay {
  border: solid 5px transparent;
  border-radius: 15px;
}

.card.card-custom.overlay:hover,
.card.card-custom.overlay.active {
  border-color: #1bc5bd;
  // outline: 5px solid #1BC5BD;
  // outline-offset : -3px
}

.mh-80px {
  max-height: 80px;
}

input[type="radio"]:checked+label {
  padding: 100px;
  background-color: red;
}

.cXcRCo {
  z-index: 999;
}

.form-control .react-datetime-picker__wrapper {
  border: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f3f6f9;
}

.card-title {
  font-family: inherit;
  font-weight: 600;
}

small.card-text {
  font-size: 1.1rem;
  font-family: inherit;
}

.btn {
  text-transform: uppercase;
}

.mt-20m {
  margin-top: -20px;
}

.my-list {
  display: flex;
  margin: 0;
  flex-wrap: wrap;
}

.my-list-item {
  display: inline-flex;
  font-size: 1.2rem;
  flex-grow: 1;
  width: 20%;
}

li.my-list-item::before {
  content: ">";
  margin-right: 10px;
  color: #000;
  font-size: 1.2rem;
}

.accordion .card.card-custom.gutter-b {
  margin: 0;
}

.accordion .card .btn.btn-link {
  padding: 0;
}

.accordion .card .card-header {
  background: #fff;
  text-align: left;
}

.radio-inline .radio.my-radio-btn,
.checkbox.my-checkbox-btn {
  // margin-right: 1rem;
  // margin-bottom: 0.35rem;
  min-width: 110px;
  min-height: 50px;
  border: 1px solid lightgrey;
  display: inline-flex;
  margin-bottom: 1.2rem;
  padding: 10px;
}

.my-checkbox-btn span,
.my-checkbox-btn {
  margin-right: 10px;
}

.thumb-image-display {
  width: 120px;
  height: 120px;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 0 15px 15px;
  overflow: hidden;
}

.thumb-image-display span.btn {
  position: absolute;
  top: -10px;
  right: -10px;
}

span.extra-label {
  padding: 2px 10px;
  background: #3699ff;
  color: #fff;
  margin-right: 10px;
  font-family: inherit;
  border-radius: 10px;
}

.my-list-group li.list-group-item span {
  font-weight: 600;
  color: grey;
}

.my-list-group li.list-group-item {
  font-size: 1.1rem;
  font-family: inherit;
  font-weight: 400;
}

a.nav-link.btn.btn-icon.btn-clean.btn-lg.active {
  color: #1bc5bd;
}

a.nav-link.btn.btn-icon.btn-clean.btn-lg {
  min-width: 100%;
  display: flex;
  justify-content: end;
  font-size: inherit;
  font-family: "Poppins";
  // color: #000;
}

.aside-nav ul.list-unstyled.flex-column {
  min-width: 120px;
}

.thumb-image-display [data-action="change"] {
  cursor: pointer;
  position: absolute;
  right: -10px;
  top: -10px;
}

.square-image-display [data-action="change"] input,
.thumb-image-display [data-action="change"] input,
.round-image-display [data-action="change"] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}

.wallet-amount {
  position: absolute;
  right: 0;
}

.filter-label {
  display: block;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}

.filter-offcanvas {
  min-width: 400px;
  max-width: 100%;
}

.filter-body.offcanvas-content {
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 20%);
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  flex-flow: column;
}

.form-control.phone-preselect {
  max-width: 100px;
  display: inline;
}

.form-control.phone-input {
  max-width: calc(100% - 110px);
  display: inline;
  margin-left: 10px;
}

.form-control.phone-preselect+div {
  display: none;
}

a.my-menu-item {
  justify-content: flex-end;
  width: 100%;
  // border-bottom: 1px solid lightgrey;
}

.aside-footer .btn.btn-clean.btn-icon.btn-lg {
  //  min-width: 33%;
  display: inline-flex;
}

.pagination .page-item.active .page-link,
.pagination .page-item .page-link:active {
  background-color: #1bc5bd !important;
  border-color: #1bc5bd !important;
  color: #ffffff !important;
}

.pagination .page-item .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  width: calc(1.35em + 1.1rem + 2px);
  height: calc(1.35em + 1.1rem + 2px);
  align-items: center;
  justify-content: center;
  display: inline-flex !important;
  color: #7e8299;
  background-color: #f3f6f9;
  border: 1px solid;
  border-color: #f3f6f9;
  border-radius: 0.42rem;
  font-size: 0.925rem;
  line-height: 1.35;
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
  margin-right: 0.5rem !important;
}

.btn.btn-import {
  height: calc(1.5em + 1.3rem + 2px);
  width: calc(5em + 1.3rem + 2px);
  overflow: visible;
  align-items: center;
  justify-content: center;
}

.btn.btn-import input[type="file"] {
  opacity: 0;
}

.currency {
  display: inline-block;
  position: relative;
  // min-width: 18px;
  width: 1.2rem;
  margin: auto 2px;
  top: -2px;
}

.setting-tabs nav.nav.nav-tabs {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.setting-tabs a.nav-item.nav-link.active {
  color: #ffffff;
  background-color: #0bb7af;
  border-color: #0bb7af;
}

.setting-tabs a.nav-item.nav-link {
  font-family: inherit;
  font-size: 1.2rem;
  border: none;
  background: #000;
  color: #ffffff;
  font-weight: 500;
  border-radius: 1rem;
  margin: 0 1rem 1rem 0;
}

select.country-select-input.form-control {
  min-width: 120px;
  max-width: 120px;
  text-transform: uppercase;
  display: inline-flex;
}

label.country-select-label {
  display: inline-flex;
  font-size: 1.1rem;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  margin: auto 10px;
}

.country-select-block {
  background: #fff;
  border: 1px solid lightgrey;
  border-radius: 20px;
  padding: 5px 1rem;
}

.country-select-block .feedback {
  display: none;
}

.country-flag {
  margin-right: 10px;
  max-height: 25px;
  position: relative;
  top: -3px;
}

span.country-name {
  font-size: 1.2rem;
}

.glob-country-select .dropdown-item {
  border-bottom: 1px solid lightgray;
  text-transform: uppercase;
}

.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-search--dropdown .select2-search__field,
.select2-dropdown {
  border: 1px solid #e4e6ef;
}

.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #1bc5bd;
  color: #fff;
}

.form-control {
  min-height: 50px;
  border-radius: 30px;
  border: 1px solid #7a7a7a;
  margin-bottom: 8px;
}

.select2-container--default .select2-selection--single {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.4rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  min-height: 50px;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .select2-selection__rendered {
    line-height: 40px;
  }

  .select2-selection__arrow {
    top: 12px;
    right: 15px;
  }
}

// .bootstrap-datetimepicker-widget table td.disabled, .bootstrap-datetimepicker-widget table td.disabled:hover {
//   background-color: lightgray;
//   columns: grey;
// }
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover,
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #1bc5bd !important;
}

.fa-clock-o:before {
  content: "\f155";
}

.fa-clock-o:before,
.fa-clock-o:before {
  font-family: Flaticon;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.input-group-append .input-group-text {
  display: none;
}

.rounded-pill .select2-container--default .select2-selection--single {
  border-radius: 50rem !important;
}

.max-h-220px {
  max-height: 220px;
}

.c-pointer {
  cursor: pointer;
}

.feedback {
  // display: none;
}

.valid-feedback,
.invalid-feedback {
  padding-left: 1.25rem;
  margin-bottom: 0.25rem;
}

.wizard.wizard-4 .wizard-nav .wizard-steps {
  background-color: #f3f6f9;
}

.wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step .wizard-wrapper {
  padding: 2rem 2rem;
}

.my-tab-list {
  display: flex;
  list-style: none;
  padding: 0;
  min-width: 100px;
  // justify-content: space-between;
}

.datetimepicker-input {
  border-radius: 50rem !important;
}

ul.filter-icons-list {
  display: flex;
  padding: 0;
  justify-content: flex-start;
  border: none;
  margin-bottom: 0;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;

    .filter-icons {
      margin-bottom: 5px;
    }
  }

  li {
    margin-right: 15px;
    padding: 0 15px;
  }
}

.day-pass-bg {
  background-color: #2c9fb8;
}

.parking-bg {
  background-color: #8f4490;
}

.visitor-bg {
  background-color: #686dc0;
}

.mitting-bg {
  background-color: #e54ea1;
}

.event-bg {
  background-color: #d60b52;
}

.filter-block .form-control,
.filter-block .select2-container--default .select2-selection--single {
  min-height: 50px;
}

.filter-block .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
}

.filter-block .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 12px;
  right: 15px;
}

.btn-blue {
  background: #2c70b8;
  color: #ffff;
}

.dashboard-mobile-fixed-position-component .filter-block {
  .form-filter {
    .row {
      @media (max-width: 1601px) {
        div:nth-child(3) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 10px;

          .filter-icons-list {
            li {
              max-width: 25%;
              width: 100%;
            }
          }
        }
      }

      @media (max-width: 1199px) {
        .filter-icons {
          .filter-icons-list {
            margin: -15px -12.5px 0;
            flex-wrap: wrap;

            li {
              max-width: calc(50% - 25px) !important;
              width: 100% !important;
              margin: 15px auto 0;
            }
          }
        }
      }

      @media (max-width: 575px) {
        .filter-icons {
          .filter-icons-list {
            flex-wrap: wrap;

            li {
              .filter-count {
                .inside_name {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  li.filter-icons {
    display: flex;
    position: relative;
    border-radius: 50rem;
    min-width: 80px;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 15px;
    margin-right: 10px;
    font-size: 14px;
    color: #fff;
  }
}

li.filter-icons {
  display: flex;
  position: relative;
  border-radius: 50rem;
  min-width: 80px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 8px;
}

.booking-item {
  display: flex;
  // min-height: 138px;
  min-height: 210px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 20%);
  border-radius: 12px;
  justify-content: start;
  // align-items: start;
  width: 100%;
  max-width: 100%;
  clear: both;
  overflow: auto;
  position: relative;

  .booking_name_onhover {
    position: absolute;
    opacity: 0;
    right: 12px;
    bottom: 10px;
    // width: 100%;
    // height: 100%;
    // background: rgba($color: #fff, $alpha: 0.7);
    z-index: 10;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease-in-out;
  }

  &:hover {
    .booking_name_onhover {
      opacity: 1;
    }
  }
}

// .booking-item-date span.item-time {
//   margin-top: -15px;
//   font-size: 16px;
//   font-weight: 700;
// }

body {
  font-family: Roboto Condensed !important;
}

.calendar-user-list-image {
  border-radius: 100%;
  width: 70px;
  height: 70px;
  object-fit: fill;
}

.filter-icons-list.all-active .filter-icons {
  opacity: 1;
}

.filter-icons-list .filter-icons.active,
.filter-icons-list:not(.all-active) .filter-icons.active {
  opacity: 1;
}

.filter-icons-list:not(.all-active) .filter-icons {
  opacity: 0.5;
}

.cancelled .parking-bg {
  background: #efc8d2;
}

.no-data .parking-bg {
  background: #d6def1;
}

// span.item-time {
//   font-size: 1.2rem;
//   margin-top: -15px;
// }

.booking-item-date.text-uppercase {
  // background: #2C9FB8;
  border-radius: 12px 0px 0px 12px;
  height: inherit;
  min-height: inherit;
  display: flex;
  max-width: 115px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 5px;
}

.booking-item-date span {
  color: #fff;
  display: flex;
  font-size: 11px;
  font-weight: 500;
  justify-content: center;
  margin: auto;
  width: 100%;

  &.item-time {
    font-size: 16px;
    font-weight: 700;
  }
}

.dashboard-booking-main-block {
  min-height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  background: url(/media/svg/icons/line-15.svg) repeat-y left top;
  padding: 0 20px;

  @media (min-width: 1600px) {
    &>.row {
      &>div {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}

.dashboard-booking-button-block button.btn-blue.active {
  background: #2c70b8;
}

.dashboard-booking-button-block button.btn-blue {
  background: #cbd4eb;
  font-size: 13px;
  font-weight: 700;
  color: #ffff;
}

svg.dashboard-booking-main-block-icon {
  position: absolute;
  left: -15px;
  top: -3px;
}

.dashboard-booking-title-block h2.dashboard-booking-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}

.dashboard-booking-title-block {
  display: flex;
  width: 100%;
  justify-content: start;
  height: 60px;
  align-items: center;
  margin-top: -15px;

  &+.row {
    width: 100%;
  }
}

.booking-item-title {
  color: #3d3d3d;
  padding-right: 42px;
  opacity: 0.66;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin-bottom: 5px;

  .meeting_room_name {
    color: #000;
    font-weight: 700;
    font-size: 18px;
  }
}

.booking-item-status.booked {
  background: #2682ff;
  color: #fff;
}

.booking-item-status.no-data {
  background: #d6def1;
  color: #3d3d3d;
}

.booking-item-status.cancelled {
  background: #efc8d1;
  color: #3d3d3d;
}

span.booking-item-owner-name {
  font-weight: 700;
}

.booking-item-owner {
  color: #3d3d3d;
  letter-spacing: 0.2px;
  font-size: 14px;
  font-weight: 300;
  line-height: 15.23px;
  margin-bottom: 5px;
}

.cancel-kiosk-button {
  border: 2px solid #f64e60;
  border-radius: 12px;
  padding: 5px;
  width: 146px;
}

.booking-item-status {
  max-width: max-content;
  font-size: 14px;
  font-weight: 300;
  line-height: 15.23px;
  padding: 3px 6px;
  border-radius: 4px;
}

.booking-item-details {
  padding: 15px;
  position: relative;
  width: 100%;
}

.booking-item-menu {
  position: absolute;
  right: 0px;
  top: 0;
  max-width: 45px;
  cursor: pointer;
  z-index: 2;
}

.booking-item-menu .dropdown-menu a.dropdown-item svg {
  margin-right: 25px;
}

.booking-item-menu .dropdown-menu a.dropdown-item.cancel-item {
  color: #a32a2a;
}

.booking-item-menu .dropdown-menu a.dropdown-item {
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
}

.booking-item-menu button.dropdown-toggle.btn.btn-light {
  padding: 0;
  margin: auto;
  border: none;
  background: transparent;
}

.booking-item-menu .btn.btn-light.dropdown-toggle:after {
  content: none;
  display: none;
}

// .booking-item-date span.item-month {
//   margin-bottom: -19px;
// }

// .booking-item-date span.item-day {
//   margin-top: -19px;
// }

.booking-item-date span.item-date {
  font-size: 2.5rem;
  display: flex;
  font-weight: 700;
  // margin: 8px 0;
  line-height: 1;
}

.filter-count {
  color: #fff;
  margin: 1px;
  font-size: 14px;
  padding-left: 5px;
  font-weight: 700;

  .inside_name {
    font-weight: 400;
    margin-left: 6px;
  }
}

.filter-block svg.date-picker-calender,
svg.date-picker-calender {
  position: absolute;
  right: 30px;
  top: 12px;
  z-index: 9 !important;
  width: 25px;
}

.filter-block .input-group-append .input-group-text {
  display: none;
}

.my-tab-list li.inline-list {
  display: inline-flex;
  text-align: center;
  padding: 1rem;
  justify-content: center;
  min-width: 100px;
  background: #f3f6f9;
  color: #000;
  cursor: pointer;
}

.color-box-list-head li.list-inline-item {
  min-width: 50px;
  max-width: 50px;
  text-align: center;
}

.color-box-list-title li {
  min-height: 50px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.color-box-list li.list-inline-item {
  min-width: 50px;
  min-height: 50px;
  // background: #F1D6E0;
  border-radius: 3px;
}

.color-box-list li.list-inline-item span.room-block-15.booked.byme,
.color-box-list li.list-inline-item span.room-block-15.booked.byme.odd,
.color-box-list li.list-inline-item span.room-block-15.booked.byme.even {
  background: #3070ae;
}

.color-box-list li.list-inline-item span.room-block-15.booked.odd {
  background: #19c501;
}

.color-box-list li.list-inline-item span.room-block-15.booked.even {
  background: #1db489;
}

.color-box-list li.list-inline-item span.room-block-15 {
  background: #f1d6e0;
  height: 100%;
  width: 25%;
  display: inline-flex;
  cursor: pointer;
}

.color-box-block-top {
  max-width: 1219px;
}

.color-box-block-top ul li.list-inline-item {
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  font-weight: 300;
}

.color-box-block-top .title-top {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 20px;
}

button.btn-room-capacity.active {
  border-color: 1px solid #e54ea1;
}

button.btn-room-capacity {
  border: 1px solid #bbc0ce;
  border-radius: 90px;
  width: 90px;
  display: flex;
  justify-content: space-between;
  padding: 8px 4px 8px 0px;
  margin: auto 0px 0 10px;
  align-items: center;
}

form.new-space-form .select2-container--default .select2-selection--single {
  border: 1px solid #aaa;
}

form.new-space-form .form-group label {
  color: rgba(0, 0, 0, 0.54);
}

form.new-space-form button.btn-room-capacity {
  border: 1px solid #bbc0ce;
  border-radius: 90px;
  /* width: 90px; */
  display: flex;
  justify-content: space-between;
  padding: 8px 4px 8px 0px;
  margin: auto 0px 0 10px;
  align-items: center;
  min-width: 31.3%;
}

.highlight-box {
  background-color: #edf2fe;
  padding: 12px;
  width: 100%;
  margin-bottom: 32px;
}

.color-box-top-left-side,
.color-box-top-right-side {
  min-height: 50px;
  align-items: center;

  .MuiInputBase-input {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 20px !important;
  }
}

.btn-room-capacity.active span.capacity-block {
  background: #e54ea1;
}

.btn-room-capacity.active svg {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

span.capacity-block {
  display: flex;
  background: #bbc0ce;
  padding: 3px 12px;
  border-radius: 90px;
  color: #fff;
  font-weight: 400;
}

.mybooking-bg {
  background: #3070ae;
}

.booked-bg {
  background: #19c501;
}

.booked-bg-two {
  background: #1db489;
}

.available-bg {
  background: #f1d6df;
}

.color-box-block-top ul li.list-inline-item span {
  width: 15px;
  height: 15px;
  display: inline-flex;
  border-radius: 3px;
  margin: auto 10px;
}

.color-box-block {
  background: #f5f7fe;
  max-width: 1219px;
  border-radius: 10px;
}

.next-btn-text {
  color: #3e3157;
  font-size: 16px;
  background: transparent;
}

.next-btn-icon {
  justify-content: center;
  margin: auto 0 auto 10px;
}

.my-tab-list li.inline-list.active {
  color: #1bc5bd;
  background-color: #fff;
}

.totalCount {
  font-size: 1.3rem;
  font-weight: 600;
  -webkit-flex: 0 0 2.75rem;
  flex: 0 0 2.75rem;
  height: 2.75rem;
  width: 2.75rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: rgba(27, 197, 189, 0.08);
  color: #1bc5bd;
  margin-right: 1rem;
  border-radius: 0.5rem;
}

.my-tab-list li.inline-list.active .totalCount {
  background-color: #1bc5bd;
  color: #fff;
}

.my-tab-list li.inline-list .wizard-label {
  margin: 10px;
  font-weight: 600;
}

.layout-px-spacing {
  padding: 0 20px 0 18px !important;
  min-height: calc(100vh - 170px) !important;
}

.task-list-section {
  display: flex;
  overflow-x: auto;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 15px;
}

.task-list-container {
  min-width: 260px;
  padding: 0 15px;
  width: 270px;
}

.connect-sorting {
  padding: 15px;
  background: #e0e6ed;
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%),
    0 1px 20px 0 rgb(0 0 0 / 7%), 0px 1px 11px 0px rgb(0 0 0 / 7%);
  -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08),
    0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0 4px 6px 0 rgb(85 85 85 / 8%), 0 1px 20px 0 rgb(0 0 0 / 7%),
    0px 1px 11px 0px rgb(0 0 0 / 7%);
}

.connect-sorting .task-container-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 5px;
}

.connect-sorting-content {
  min-height: 80px;
}

.connect-sorting .task-container-header h6 {
  font-size: 16px;
  font-weight: 700;
}

.scrumboard .card {
  border: none;
  border-radius: 4px;
  margin-bottom: 30px;
  -webkit-box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 10%);
  box-shadow: 2px 5px 17px 0 rgb(31 45 61 / 10%);
}

.scrumboard-back {
  background: #fff;
  min-height: 150px;
  padding: 1rem;
  display: flex;
  margin-bottom: -80px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  max-height: 300px;
  text-align: center;
}

.in-game-slot .input-group-text {
  width: 42px;
}

.in-game-slot .input-group-prepend,
.input-group-append {
  max-height: 40px;
}

.aside-nav.scroll.scroll-pull {
  max-height: 80vh;
}

.in-game-slot .col-lg-2.input-group {
  margin-bottom: 1.25rem;
}

.is-invalid-select .invalid-feedback {
  display: block;
}

.is-invalid-select .select2-container--default .select2-selection--single {
  // border-color: red;
}

.min-h-100px {
  min-height: 100px;
}

label.required:after {
  content: "* ";
  color: red;
  margin-left: 0.3rem;
}

.profile-pic .symbol.symbol-xxl-100 .symbol-label {
  width: auto !important;
}

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  vertical-align: baseline;
}

.report-filter-bLock {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: start;
  align-items: start;
  border-bottom: 1px solid lightgrey;
  margin: auto auto 10px;
}

.avatar-card-select .overlay-wrapper img {
  max-height: 100%;
  box-sizing: border-box;
  max-width: 100%;
  object-fit: contain;
}

.avatar-card-select .overlay-wrapper {
  max-width: 100%;
  height: 100px;
  width: 100px;
}

.gamenames .feedback {
  display: none;
}

.ob-fit-cover {
  object-fit: cover;
}

// .input-group:not(.has-validation) > .form-control:not(:last-child), .input-group:not(.has-validation) > .custom-select:not(:last-child), .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
//    border-top-right-radius: 0.42rem;
//    border-bottom-right-radius: 0.42rem;
// }
.mh-300 {
  max-height: 300px;
}

.modal-header {
  .modal-title.h4 {
    font-size: 1rem !important;
    font-weight: 700;
  }
}

.modal-footer {
  border: unset;
}

.modal-close {
  color: $dark;
  font-size: 1.8rem;
  cursor: pointer;
}

.booking-options-modal {
  .row {
    align-items: center;
  }

  .svg-icon svg {
    height: 45px;
    width: 45px;
  }
}

.p-07 {
  padding-right: 0.7rem !important;
}

.custom-control-label::before {
  background-color: #dbdad9;
}

.modal-content {
  .custom-switch {
    .custom-control-label::after {
      background-color: #ffffff;
    }
  }
}

/* IRFAN S - CSS 19-Sep-2022 */

.btn-friend {
  height: 44px;
  width: 136px;
  left: 236px;
  top: 8px;
  border-radius: 90px;
  padding: 12px;
  border: 1px solid black;
  font-weight: bold;
}

.dropdown-toggle {
  background: #fff !important;
  border: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

/* IRFAN S - CSS 20-Sep-2022 */

.add-friend {
  color: #2c70b8;
}

.add-friend:hover {
  color: #2c70b8 !important;
  background-color: #ebedf3;
}

.remove-friend {
  color: #a32a2a;
}

.remove-friend:hover {
  color: #a32a2a;
  background-color: #ebedf3;
}

.modal.modal-center {
  .modal-dialog {
    .modal-header {
      .close {
        color: #3d3d3d;
        font-size: 28px;
        opacity: 1;

        &:hover {
          color: #005fcc;
        }
      }
    }
  }
}

/* IRFAN - CSS 08-Sep-2022 */
.vehicle-item,
.notification-item,
.visitor-item {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 0px 11px 0px 0px;
  gap: 4px;
  width: 100%;
  /* height: 100%; */
  background: #f5f7fe;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.vehicle-lbl {
  width: 217.98px;
  height: 12.52px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;

  /* inactive */

  color: #7a7a7a;
}

.vehicle-no {
  width: 217.98px;
  height: 15.25px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 15.6351px;
  line-height: 18px;
  display: flex;
  align-items: center;

  /* grey */

  color: #3d3d3d;
}

button.add-vehicle {
  border: 1px solid;
  border-radius: 30px;
  font-weight: bold;
}

.actions.align-items-center {
  max-width: 157px;
  width: 100%;
}

.vehicle-actions.align-items-center {
  max-width: 112px;
  width: 100%;
}

.notification-sender {
  width: 217.98px;
  height: 12.52px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  /* inactive */

  color: #7a7a7a;
}

.notification-message-notif {
  width: 100%;
  height: 15.25px;

  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 15.6351px;
  line-height: 18px;
  display: flex;
  align-items: center;

  /* grey */

  color: #3d3d3d;
}

.vehicle-details-main .vehicle-lbl {
  font-size: 12px;
  margin-bottom: 5px;
}

div.modal.show .close {
  font-size: 2rem;
  margin: -7px -7px 6px 0;
}

.notif-list .common-setting-div {
  padding: 20px !important;
}

@media (max-width: 600px) {
  #kt_body .subheader {
    display: none;
  }

  .login.login-4 .login-aside {
    display: none;
  }

  .dashboard-booking-label-block {
    display: none;
  }

  .booking-item.no-booking-card {
    display: none;
  }

  .aside-secondary-disabled .aside.aside-on,
  #kt_body.aside-minimize .aside.aside-on {
    width: 292px !important;
  }

  .aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav>.menu-item>.menu-link .menu-bullet,
  .aside-minimize:not(.aside-minimize-hover) .aside-menu .menu-nav>.menu-item>.menu-link .menu-text {
    display: flex;
  }
}

.modal-create-work-circle .form-control {
  border: 1px solid #c7c3c36b;
}

form.work-circle-details {
  border: 0;
  padding: 15px;
  border-radius: 10px;
  background: #f5f7fe !important;
  margin-top: 10px;
}

form.work-circle-details #scrollableDiv {
  margin-top: 10px;
}

form.work-circle-details .MuiTypography-root {
  margin-bottom: 10px;
}

form.work-circle-details .form-control,
.add-workcircle-modal .form-control {
  border: 1px solid #ebedf3;
  margin-bottom: 0px;
}

form.work-circle-details .work-circle-item-pic {
  margin: 6px;
}

form.work-circle-details .add-remove-label {
  margin-bottom: 10px;
}

form.work-circle-details .work-circle-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 11px 0px 0px;
  gap: 4px;
  width: 100%;
  flex: none;
  order: 0;
  flex-grow: 1;
  border-bottom: 1px solid #ebedf3;
}

.setting-box {
  margin: 0;
  padding: 3.75rem 20px !important;

  .user-settings {
    max-width: 100%;
  }

  .tab-setting {
    max-width: 100%;
    overflow: auto;

    .MuiTabs-fixed {
      overflow: auto !important;
    }
  }

  .vehicle-info {
    max-width: calc(100% - 112px);
  }
}

.work-circle-info .notification-sender {
  width: 100%;
  line-height: normal;
}

.work-circle-info {
  max-width: calc(100% - 160px);

  .max-w-70px {
    min-width: 70px;
  }
}

.add-workcircle-modal {
  .add-work-circle-modal {
    .infinite-scroll-component {
      max-height: calc(100vh - 466px);
      height: calc(100vh - 466px) !important;
      margin-top: 20px;
    }
  }
}

.add-workcircle-modal {
  .infinite-scroll-component {
    max-height: calc(100vh - 288px);
    height: calc(100vh - 288px) !important;
    margin-top: 20px;
  }
}

.add-work-circle-modal .notification-item.list-group-item.mt-4.d-flex {
  width: 95%;
}

.modal-create-work-circle,
.add-vehicle-modal.modal {
  @media screen and (max-width: 576px) {
    padding-right: 0 !important;
  }

  .modal-dialog {
    @media screen and (max-width: 576px) {
      margin-left: 10px !important;
      width: calc(100% - 20px) !important;
      margin-right: 10px !important;
      max-width: none;
    }
  }
}

.pending-friend-block .resend-link {
  font-size: 14px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  font-size: 15.7166px;
  line-height: 18px;
  display: flex;
  color: #d60b52;
}

/*IRFAN S CSS : 22-Sept-2022 */

.day-pass-report-icon {
  width: 110px !important;
  height: 75px !important;
  min-width: 100px;
  margin-top: 0 !important;
}

.day-passes-report-box {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.day-pass-main {
  .day-pass-card {
    background: #fff;
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 10px;
    height: calc(100% - 5px);

    @media screen and (max-width: 991px) {
      margin-bottom: 5px;
    }
  }

  .day-pass-title {
    font-family: "Roboto Condensed" !important;
    font-size: 15px !important;
    font-weight: bold !important;
    margin-top: 15px !important;
  }

  .day-pass-ocupancy {
    font-size: 8px;
  }

  .day-pass-percentage {
    font-size: 15px;
  }

  .vergesense-icon {
    width: 100px;
    height: 75px;
  }

  .count-verge {
    font-size: 13px;
    padding: 3px;
    background: #d6def1;
    border-radius: 3px;
    font-weight: 400;
  }

  .count-verge-bold {
    font-weight: bold;
  }

  .count2-verge {
    font-size: 13px;
    padding: 3px;
    background: #d6def1;
    margin-left: 5px;
    font-weight: 400;
    border-radius: 3px;
  }

  .count2-verge-bold {
    font-weight: bold;
  }
}

// Jaydeep Css Styles...
@media (max-width: 600px) {
  .calendar-book-span {
    position: absolute;
    top: 50px;
    margin-left: 2px;
  }

  .calendar-book-active {
    padding: 0px 6px;
  }

  .plus-color {
    padding: 3px;
    border-radius: 100%;
    margin-left: 2px;
    background: #edf2fe;
    color: #2c70b8;
  }

  .dashboard-filter-boxes {
    display: none;
  }

  .daskboard-booking-filter-buttons {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .dashboard-mobile-fixed-position-component {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #f3f6f9;
    border-radius: 12px;
    z-index: 99;
    width: 100%;
  }

  .dashboard-booking-title-block {
    // margin-left: 1rem;
  }

  .dashboard-booking-card-block {
    // margin-left: 4rem;
  }
}

// IRFAN S - 23-Sep-2022
.day-pass-main {
  .daypass-donult {
    background: #edf2fe;
  }

  .daypass-item {
    display: flex;
    min-height: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 16%);
    border-radius: 12px;
    justify-content: start;
    // align-items: start;
    width: 100%;
    // max-width: 325px;
  }

  .daypass-item-date span.item-time {
    margin-top: -25px;
    font-size: 16px;
    font-weight: 700;
  }

  .daypass-item-date span.item-month {
    margin-bottom: -19px;
  }

  .daypass-item-date span.item-day {
    margin-top: -19px;
  }

  .daypass-item-date span.item-date {
    font-size: 2.5rem;
    display: flex;
    font-weight: 700;
    margin: 8px 0;
  }

  .daypass-item-date span.time-duration {
    font-size: 17px;
    font-weight: 700;
  }

  .daypass-item-date.text-uppercase {
    // background: #2C9FB8;
    /* border-radius: 12px; */
    display: flex;
    max-width: 100px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    min-width: 100px;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .daypass-item-date span {
    color: #fff;
    display: flex;
    font-size: 11px;
    font-weight: 500;
    justify-content: center;
    margin: 0;
    width: 100%;
  }

  .daypass-item-details {
    padding: 10px 10px 10px;
    position: relative;
    width: 100%;
  }

  .daypass-item-title {
    color: #3d3d3d;
    padding-right: 42px;
    opacity: 0.66;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  span.daypass-item-owner-name {
    font-weight: 700;
  }

  .daypass-item-owner {
    color: #3d3d3d;
    letter-spacing: 0.2px;
    font-size: 14px;
    font-weight: 300;
    line-height: 15.23px;
    margin-bottom: 5px;
  }

  .cancelled-bg {
    background-color: #efc8d2;
  }

  .nodata-bg {
    background-color: #d6def1;
  }

  .daypass-item-title .nodata-status.no-show {
    background-color: #005fcc;
    color: #ffffff !important;
  }

  .daypass-item-title .cancle-status {
    border-radius: 4px;
    padding: 2px;
    font-size: 12px;
    color: #000 !important;
    font-weight: 500;
  }

  .daypass-item-title .nodata-status {
    border-radius: 4px;
    padding: 3px;
    font-size: 12px;
    color: #000 !important;
    font-weight: 500;
  }

  .btn-daypass {
    background: #2c9fb8;
    color: #ffff;
  }

  .btn-parking {
    background: #8f4490;
    color: #ffffff;
  }

  .btn-visitor {
    background: #686dc0;
    color: #ffff;
  }

  .btn-meeting {
    background: #e54ea1;
    color: #ffff;
  }

  .dashboard-booking-button-block button.btn-parking.active {
    background: #8f4490;
  }

  .dashboard-booking-button-block button.btn-visitor.active {
    background: #686dc0;
  }

  .dashboard-booking-button-block button.btn-daypass.active {
    background: #2c9fb8;
  }

  .dashboard-booking-button-block button.btn-meeting.active {
    background: #e54ea1;
  }

  .dashboard-booking-button-block button.btn-parking {
    background: #cbd4eb;
    font-size: 13px;
    font-weight: 700;
    color: #ffff;
  }

  .dashboard-booking-button-block button.btn-visitor {
    background: #cbd4eb;
    font-size: 13px;
    font-weight: 700;
    color: #ffff;
  }

  .dashboard-booking-button-block button.btn-daypass {
    background: #cbd4eb;
    font-size: 13px;
    font-weight: 700;
    color: #ffff;
  }

  .dashboard-booking-button-block button.btn-meeting {
    background: #cbd4eb;
    font-size: 13px;
    font-weight: 700;
    color: #ffff;
  }

  .daypass-count2-verge {
    font-size: 15px;
    padding: 0 7px 0 7px;
    background: #d6def1;
    margin-left: 5px;
    font-weight: 400;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .daypass-verge {
    font-size: 15px;
    padding: 0 7px 0 7px;
    background: #d6def1;
    border-radius: 3px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .daypass-verge-bold {
    font-weight: bold;
  }
}

.bg-booking {
  background-color: #686dc0;
}

.bg-meeting {
  background-color: #e54ea1;
}

.calendar-settings-form .custom-switch .custom-control-label::after,
.notification-settings-form .custom-switch .custom-control-label::after {
  background-color: #ffffff;
}

.modal-create-work-circle.modal.show .modal-body {
  padding-top: 0;
}

.add-workcircle-modal li.add-work-circle-item,
.add-friend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 11px 0px 0px;
  grid-gap: 4px;
  gap: 4px;
  width: 100%;
  flex: none;
  order: 0;
  flex-grow: 1;
  border-bottom: 1px solid #ebedf3;
}

.dash-csv-button {
  height: 48px;
  width: 239px;
  margin-left: 250px;
  margin-top: 15px;
  border-radius: 90px;
  padding: 0px 0px 0px 12px;
  color: #3e3157;
  border: 1px solid #3e3157;
}

.dash-csv-button:focus {
  background: #ffffff !important;
}

// Irfan M 27-09-2022

.multi-select-box {
  height: auto;
  padding: 6px 10px;

  .multiselect-container {
    padding: 5px 0;

    .chip {
      padding: 2px 7px;
      margin: 5px 8px 6px 5px;
      border-radius: 50px;
      margin-bottom: 5px;
      margin-left: 0;
      background-color: #1bc5bd;
      font-size: 12px;
      color: #fff;

      i {
        color: #fff;

        &::before {
          content: "\e801";
        }
      }
    }

    input {
      cursor: pointer;
    }
  }

  .optionListContainer {
    li {
      border-radius: 0;

      &:hover,
      &.highlight:hover {
        background-color: #1bc5bd;
        color: #fff;
      }

      &.highlight {
        background-color: transparent;
        color: #000;
      }
    }
  }
}

.day-pass-main .select2-container--default .select2-selection--single,
.form-filter .select2-container--default .select2-selection--single {
  border-color: #7a7a7a;
}

.img-opacity {
  width: 100px;
  height: 100%;
  object-fit: cover;
  position: relative;

  &.blue-opacity+.meetin-time-info {
    background-color: rgba(44, 159, 184, 0.5);
  }

  &.pink-opacity+.meetin-time-info {
    background-color: rgb(229, 78, 161, 0.5);
  }
}

.meetin-time-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img-opacity+.meetin-time-info {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  width: 100px;
  z-index: 5;
  background: rgba(0, 0, 0, 0.3);
}

/* IRFAN CSS - 29-09-2022 */
.daypass-txt-lbl {
  font-size: 22px;
}

.reporting-filters-form .select2-selection__placeholder {
  color: #7a7979 !important;
}

.subheader form input.form-control {
  margin-bottom: 0 !important;
}

.reporting-filters-form .select2-selection__rendered {
  margin-top: -2px;
}

/* IRFAN CSS - 29-09-2022 */

.friend-button-icon {
  color: #181c32 !important;

  .friend-plus-icon {
    width: 15px !important;
    font-weight: bold !important;
    margin-bottom: 3px !important;
    color: #181c32;
  }
}

.circle-button {
  border: none !important;
  background: none !important;
}

// .MuiInputLabel-outlined {
//   transform: translate(14px, 17px) scale(1) !important;
// }
// .MuiInputLabel-outlined.MuiInputLabel-shrink {
//   transform: translate(14px, -6px) scale(0.75) !important;
// }
.account-setting-main {
  .MuiGrid-container {
    column-gap: 10px;
    flex-wrap: nowrap;
    padding: 0 10px;
  }

  .MuiGrid-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
    justify-content: space-between;
    padding-left: 10px;
  }
}

.cancel-timing-section {
  .MuiChip-clickable {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.add-booking-btn {
  height: 28px;
}

.middle-icn {
  margin-left: 50px;
  margin-right: 50px;
}

.modal-90w {
  max-width: 40% !important;
}

// Hitarth
.general-user-icon {
  background: #c6c6c6;
  padding: 5px;
}

.padding-0 {
  padding: 0px;
}

.padding-right-20 {
  padding-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

form.new-space-form .select2-container--default .select2-selection--single {
  border: 1px solid #c1bfbf;
}

.margin-left-40 {
  margin-left: 40px;
}

.z-index-999 {
  z-index: 999;
}

.right-55 {
  right: 140px;
}

.svg-green {
  left: 150px;
}

.eighty-percent-left {
  left: -25px;
}

.m-right-30 {
  margin-right: 30px;
}

.bottom-60 {
  bottom: 60px;
}

.pointer-none {
  pointer-events: none;
}

.color-picker-third-div:nth-child(3) {
  width: 250px !important;
}

.color-picker-third-div:nth-child(2) {
  width: 250px !important;
}

.color-picker-third-div:nth-child(4) {
  width: 250px !important;
}

.maintaince {
  margin-left: 40%;
  margin-top: 20%;
  font-size: 40px;
}

.is_vs_checkin {
  margin-left: 20px;
}

.float-right {
  float: right;
}

.flex {
  display: flex;
}

.m-l-50 {
  margin-left: 50px;
}

.svg-color {
  g [fill] {
    fill: #ffffff !important;
  }


}

.modal-body {
  padding-top: 0px;
}

.full-width-imp
{
  width: 100% !important;
}