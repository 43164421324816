.event-step1-main {
  .square-image-display {
    border-radius: 10px;
    border: 2px dotted lightgrey;
  }

  .css-yk16xz-control {
    border-radius: 5px !important;
  }

  .active-text {
    margin-left: 5px;
    color: #3e3157;
  }

  .default-text {
    margin-left: 5px;
    color: #7a7a7a;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px !important;
  }

  .event-details {
    background: #f5f7fe;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 22px 12px;
    gap: 12px;
  }

  .MuiInputBase-input {
    border: none !important;
    height: 1.5em !important;
  }


  .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }

  textarea.event-text-aria {
    background: #f5f7fe;
  }

  .event-text-area {
    width: 100%;
    border-radius: 20px;
    height: 100px;
    padding: 10px;
  }
}

.radio_parent_choose_event {
  position: relative;
  flex: 1;
  margin-top: 15px;

  &>label {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    margin: 0;
  }

  &>.cursor-pointer {
    &>svg {
      margin: auto;
    }

    &>span {
      display: block;
      text-align: center;
    }
  }
}

.full-width {
  width: 100%;
}

.css-yk16xz-control {
  background: #f5f7fe;
}



.css-1hb7zxy-IndicatorsContainer {
  background: #f5f7fe !important;
}

.css-g1d714-ValueContainer {
  background: #f5f7fe !important;
  border-radius: 5px !important;
}

.css-yk16xz-control {
  background: #f5f7fe !important;
}

.css-1wa3eu0-placeholder {
  padding: 0.2rem !important;
}

.css-1pahdxg-control {
  border-radius: 5px !important;
  border: none !important;
  color: #3f4254 !important;
  box-shadow: #17c191 !important;
}

.event-text-aria {

  border-style: solid;
  border-color: #bdbec3;
  border-width: thin
}

.bulletin-form .form-control {
  border-radius: 5px !important;
  background: #f5f7fe !important;
  border: 1px solid #bdbec3 !important;
}

._3vLmCG3bB3CM2hhAiQX1tN {
  background: #f5f7fe;
  z-index: 9999;
}

._3vt7_Mh4hRCFbp__dFqBCI input {
  margin-top: 0px;
  margin-right: 10px;
}

::placeholder {
  color: black;
  font-weight: 500;
}

textarea:focus {
  outline: none !important;
  border-color: #60dc62;
  border: 1px solid #60dc62 !important;
}

.event-text-aria:focus {
  border: 2px solid #4fb451 !important;
}

.multi-select-box .multiselect-container .chip {
  border-radius: 5px;
}

.multi-select-box .multiselect-container .chip {
  border-radius: 5px !important;
}

#cke_1_contents {
  height: 100px !important;
}

.css-g1d714-ValueContainer {
  background: #f5f7fe !important;
}

.css-1hb7zxy-IndicatorsContainer {
  background: #f5f7fe !important;
}