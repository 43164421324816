.event-main {
  .svg-icon svg {
    height: 20px;
  }

  .svg-icon svg g [fill] {
    fill: #fff;
    font-size: 10px;
  }
}

.bg-color-bulletin {
  background-color: #767676;
  border-radius: 50%;
}

.bulletin-svg {

  padding-right: 10px;
  padding-left: 10px;
}