.event-step2-main {
  .event-select-time {
    background-color: #f5f7fe;
  }

  .event-day-status {
    background-color: #a5f1da;
  }

  .event-select-location {
    background-color: #f5f7fe;
  }

  .MuiInputBase-input {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 30px !important;
  }

  .MuiFormControl-root.MuiTextField-root {
    width: 100% !important;
  }

  .time-picker-main .time-picker-inner .datetimepicker-input {
    color: #000;
  }

  .limited-main-class {
    padding: 10px;
    margin-top: 20px;
  }

  .remove-bulletin {
    padding: 10px;
    margin-top: 10px;
  }

  .time-picker-main .time-picker-inner svg {
    z-index: 9 !important;
  }

}

.css-g1d714-ValueContainer {
  background: white !important;
}

.css-1hb7zxy-IndicatorsContainer {
  background: white !important;
}

.css-26l3qy-menu {
  z-index: 99999 !important;
  position: relative !important;

}

.css-2b097c-container {
  z-index: 0 !important;

}

.css-yk16xz-control {
  position: absolute;
  z-index: 0 !important;
}

.height-changes {
  min-height: 900px;
}