.main_svg_wrapper_workstation_mobile {
  // margin: 15px 0;
  box-sizing: border-box;
  // border: 2px dashed rgb(150, 150, 150);
  position: relative;

  .circular_progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.6);

    & > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    width: 100%;
    height: fit-content;

    &.heleon_svg {
      circle {
        fill: transparent;
      }
    }

    circle {
      &.selected {
        fill: rgb(0, 255, 72);
        cursor: pointer;
      }
      &.occupied {
        fill: rgb(214, 11, 82);
        cursor: pointer;
      }
    }

    path {
      // cursor: pointer;
      &.selected {
        fill: rgba(0, 255, 72, 0.36);
        cursor: pointer;
      }
      &.occupied {
        fill: rgba(214, 11, 82, 0.36);
        cursor: pointer;
      }
    }
  }
}
