.main_svg_wrapper_meeting_floor {
  box-sizing: border-box;
  position: relative;

  .circular_progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #fff, $alpha: 0.6);

    & > div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    width: 100%;
    height: fit-content;

    &.heleon_svg {
      circle {
        fill: rgb(8, 235, 31);
      }
    }

    circle {
      &.selected {
        fill: rgb(233, 147, 19);
        cursor: pointer;
      }
      &.assignedAlready {
        fill: rgb(214, 11, 82);
        cursor: pointer;
      }
    }

    path {
      // cursor: pointer;
      &.selected {
        fill: rgba(233, 147, 19, 0.36);
        cursor: pointer;
      }
      &.assignedAlready {
        fill: rgba(214, 11, 82, 0.36);
        cursor: pointer;
      }
    }
  }
}

.custom_popover {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  position: fixed;
  padding: 10px 15px;
  box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
  border-radius: 6px;
  background: #fff;
  border: 1px solid #dedede;

  &.showed {
    opacity: 1;
    visibility: visible;
  }
}

.seats_info {
  .available {
    position: relative;
    padding-left: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-color: rgba(3, 172, 3, 0.36);
    }
  }
  .assigned-available {
    position: relative;
    padding-left: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-color: rgba(233, 147, 19, 0.36);
    }
  }
  .assigned-used {
    position: relative;
    padding-left: 30px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      background-color: rgba(214, 11, 82, 0.36);
    }
  }
}
