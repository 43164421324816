.event-form-main {
    .MuiMobileStepper-dotActive {
        background: linear-gradient(#00a3e5 21%, #d6225d 96.31%);
    }

    .MuiMobileStepper-root {
        background: none;
    }

    .event-custom-button {
        background: linear-gradient(98.96deg, #00a3e5 21%, #d6225d 96.31%);
        border-radius: 52.1654px;
        color: #fff;
    }
}

.bulletin-modal-popup {
    overflow: auto;
    max-height: 600px;
    padding-top: 0px !important;

}

.bulletin-header-pop-up {
    padding-bottom: 0px !important;
}

.modal-header {
    padding-bottom: 0px;
}

.modal-body {
    padding-bottom: 0px;
}

.event-form-main.modal-body {
    min-height: 600px;
}

.event-listing-main {
    .event-booking-button {
        .event-booking-filter-buttons {
            button.btn-event.active {
                background: #05c3ff;
            }

            button.btn-event {
                background: #cbd4eb;
                font-size: 13px;
                font-weight: 700;
                color: #ffff;
            }
        }
    }

    .events_list {
        .list_one {
            // border: 1px solid #dedede;
            border-radius: 12px;
            // padding: 10px;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            margin-top: 20px;
            display: flex;

            // event booking block

            .event-item-date span.item-time {
                margin-top: -25px;
                font-size: 14px;
                font-weight: 700;
            }

            .event-item-date span.item-month {
                margin-bottom: -19px;
            }

            .event-item-date span.item-day {
                margin-top: -19px;
            }

            .event-item-date span.item-date {
                font-size: 2.5rem;
                display: flex;
                font-weight: 700;
                margin: 8px 0;
            }

            .event-item-date span.time-duration {
                font-size: 17px;
                font-weight: 700;
            }

            .event-item-date.text-uppercase {
                // background: #2C9FB8;
                /* border-radius: 12px; */
                display: flex;
                max-width: 100px;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                overflow: hidden;
                position: relative;
                min-width: 140px;
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
                // height: 100px;
            }

            .event-item-date span {
                color: #fff;
                display: flex;
                font-size: 14px;
                font-weight: 500;
                justify-content: center;
                margin: 0;
                width: 100%;
            }

            .image {
                max-width: 250px;
                width: 100%;
                height: 210px;

                position: relative;

                &>img {
                    // border-radius: 5px;
                    width: 100%;
                    height: 210px;
                    object-fit: cover;
                }

                .dropdown {
                    position: absolute;
                    top: 0;
                    right: 5px;

                    button {
                        background: transparent !important;
                        padding: 0;

                        svg {
                            circle {
                                fill: #fff;
                            }
                        }
                    }
                }

                .event_location {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    padding: 20px;
                    width: 100%;
                    // height: 50%;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), transparent);
                    // border-bottom-left-radius: 10px;
                    // border-bottom-right-radius: 10px;

                    img {
                        margin-right: 8px;
                    }
                }
            }

            .event_detail {
                padding: 12px 12px 12px 20px;
                position: relative;
                max-width: calc(100% - 300px);
                width: 100%;

                .attending {
                    padding: 2px 5px;
                    background: #a5f1da;
                    color: #000000;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    border-radius: 4px;
                    font-size: 14px;
                    text-transform: uppercase;
                }

                .locate_map {
                    padding: 2px 5px;
                    position: absolute;
                    bottom: 12px;
                    right: 12px;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 14px;
                    background: #d60b52;
                    border-radius: 4px;
                }

                .capacity {
                    margin-top: 6px;
                    // display: flex;
                    // justify-content: space-between;

                    .cap_images {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;

                        img {
                            margin-right: 5px;
                        }

                        .plus_howmany {
                            width: 25px;
                            height: 25px;
                            border: 1px solid #00a3e5;
                            border-radius: 50%;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            color: #00a3e5;
                            font-size: 13px;
                            margin-left: 4px;
                        }
                    }
                }

                .capacity-invite {
                    margin-top: 6px;
                    display: flex;
                    // justify-content: space-between;
                }

                .host {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;

                    // img {
                    //   border-radius: 50%;
                    //   object-fit: center;
                    //   margin-right: 10px;
                    // }
                }
            }
        }
    }
}